define("discourse/plugins/discourse-locations/discourse/components/add-location-controls", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "jquery", "discourse/components/d-button", "discourse/plugins/discourse-locations/discourse/components/modal/add-location", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _didInsert2, _service, _jquery, _dButton, _addLocation, _locationUtilities, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AddLocationControlsComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    didInsert() {
      (0, _jquery.default)(".title-and-category").toggleClass("location-add-no-text", this.iconOnly);
    }
    static #_4 = (() => dt7948.n(this.prototype, "didInsert", [_object.action]))();
    get iconOnly() {
      return this.args.noText || this.siteSettings.location_add_no_text;
    }
    get valueClasses() {
      let classes = "add-location-btn";
      if (this.args.noText) {
        classes += " btn-primary";
      }
      return classes;
    }
    get valueLabel() {
      return this.args.noText ? "" : (0, _locationUtilities.locationFormat)(this.args.location, this.site.country_codes, this.siteSettings.location_input_fields_enabled, this.siteSettings.location_input_fields);
    }
    get addLabel() {
      return this.args.noText ? "" : "composer.location.btn";
    }
    showAddLocation() {
      return this.modal.show(_addLocation.default, {
        model: {
          location: this.args.location,
          categoryId: this.args.category.id,
          update: location => {
            if (this._state !== "destroying") {
              this.args.updateLocation(location);
            }
          }
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "showAddLocation", [_object.action]))();
    removeLocation() {
      this.location = null;
      this.args.updateLocation(location);
    }
    static #_6 = (() => dt7948.n(this.prototype, "removeLocation", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="location-label" {{didInsert this.didInsert}}>
          {{#if @location}}
            <DButton
              @class={{this.valueClasses}}
              @title="location.label.add"
              @action={{action "showAddLocation"}}
              @translatedLabel={{this.valueLabel}}
              @icon="location-dot"
            />
            {{#unless @noText}}
              <DButton
                @icon="xmark"
                @action={{action "removeLocation"}}
                @class="remove"
              />
            {{/unless}}
          {{else}}
            {{#if this.iconOnly}}
              <DButton
                @class="add-location-btn"
                @icon="location-dot"
                @action={{action "showAddLocation"}}
                @title={{this.addLabel}}
              />
            {{else}}
              <DButton
                @class="add-location-btn"
                @icon="location-dot"
                @label={{this.addLabel}}
                @action={{action "showAddLocation"}}
                @title={{this.addLabel}}
              />
            {{/if}}
          {{/if}}
        </div>
      
    */
    {
      "id": "o3JLBlds",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"location-label\"],[4,[32,0],[[30,0,[\"didInsert\"]]],null],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[8,[32,1],null,[[\"@class\",\"@title\",\"@action\",\"@translatedLabel\",\"@icon\"],[[30,0,[\"valueClasses\"]],\"location.label.add\",[28,[31,1],[[30,0],\"showAddLocation\"],null],[30,0,[\"valueLabel\"]],\"location-dot\"]],null],[1,\"\\n\"],[41,[51,[30,2]],[[[1,\"          \"],[8,[32,1],null,[[\"@icon\",\"@action\",\"@class\"],[\"xmark\",[28,[31,1],[[30,0],\"removeLocation\"],null],\"remove\"]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,0,[\"iconOnly\"]],[[[1,\"          \"],[8,[32,1],null,[[\"@class\",\"@icon\",\"@action\",\"@title\"],[\"add-location-btn\",\"location-dot\",[28,[31,1],[[30,0],\"showAddLocation\"],null],[30,0,[\"addLabel\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,1],null,[[\"@class\",\"@icon\",\"@label\",\"@action\",\"@title\"],[\"add-location-btn\",\"location-dot\",[30,0,[\"addLabel\"]],[28,[31,1],[[30,0],\"showAddLocation\"],null],[30,0,[\"addLabel\"]]]],null],[1,\"\\n\"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@location\",\"@noText\"],false,[\"if\",\"action\",\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/components/add-location-controls.js",
      "scope": () => [_didInsert2.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AddLocationControlsComponent;
});