define("discourse/plugins/discourse-locations/discourse/connectors/composer-fields/composer-controls-location", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      updateLocation(location) {
        (0, _object.set)("model.location", location);
      }
    }
  };
});